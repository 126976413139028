<template>
    <footer id="footer" class="site-footer">
        <div class="container">
          <div class="newsletter">
            <span class="newsletter__title">Newsletter <strong>Subscribe</strong></span>
            <form class="general-form newsletter__form">
              <div class="form-module">
                <div class="form-set">
                  <input type="email" placeholder="Your Email Address">
                </div>
                <button type="submit" class="btn-secondary"><span class="icon-send"></span></button>
              </div>
            </form>
          </div>
          <div class="footnote">
            <div class="row">
              <div class="col xs-6 sm-3 md-2">
                <div class="footnote-piece">
                  <span class="footnote-piece-title">Quick Links</span>
                  <ul class="footnote-piece-list">
                    <li><router-link :to="{name:'Blog'}">Travel Blog</router-link></li>
                    <li><router-link :to="{name:'About'}">Company Info</router-link></li>
                    <li><router-link :to="{name:'Page',params:{slug:'travel-info'}}">Travel Info</router-link></li>
                    <li><router-link :to="{name:'Team'}">Our Team</router-link></li>
                    <li><router-link :to="{name:'Page',params:{slug:'faqs'}}">FAQ</router-link></li>
                    <li><router-link :to="{name:'Contact'}">Contact</router-link></li>
                  </ul>
                </div>
              </div>
              <div class="col xs-6 sm-3 md-2">
                <div class="footnote-piece">
                  <span class="footnote-piece-title">Useful Links</span>
                  <ul class="footnote-piece-list">
                    <li><router-link :to="{name:'Page',params:{slug:'legal-documents'}}">Legal Documents</router-link></li>
                    <li><router-link :to="{name:'Page',params:{slug:'corporate-social-responsibility-csr'}}">CSR</router-link></li>
                    <li><router-link :to="{name:'Page',params:{slug:'payment-terms-and-conditions'}}">Payment T &amp; C</router-link></li>
                    <li><router-link :to="{name:'Page',params:{slug:'cancellation-policy'}}">Cancellation Policy</router-link></li>
                    <li><router-link :to="{name:'Page',params:{slug:'privacy-policy'}}">Privacy Policy</router-link></li>
                  </ul>
                </div>
              </div>
              <div class="col xs-6 sm-3 md-2">
                <div class="footnote-piece">
                  <span class="footnote-piece-title">Activities</span>
                  <ul class="footnote-piece-list">
                    <li><router-link :to="{name:'Packagecategory',params:{slug:'trekking'}}">Trekking</router-link></li>
                    <li><router-link :to="{name:'Packagecategory',params:{slug:'peak-climbing'}}">Peak Climbing</router-link></li>
                    <li><router-link :to="{name:'Packagecategory',params:{slug:'tours'}}">Tour</router-link></li>
                    <li><router-link :to="{name:'Packagecategory',params:{slug:'hiking'}}">Activities</router-link></li>
                  </ul>
                </div>
              </div>
              <div class="col xs-6 sm-3 md-3">
                <div class="footnote-piece">
								<span class="footnote-piece-title">Associated With</span>
								<ul class="footnote-piece-elist associates">
									<li><span class="svg-wrap svg-coatofarms" title="Coat of Arms"><svg><use xlink:href="#svg-associate-coatofarms"></use></svg></span></li>
									<li><span class="svg-wrap svg-ntb" title="NTB"><svg><use xlink:href="#svg-associate-ntb"></use></svg></span></li>
									<li><span class="svg-wrap svg-taan" title="TAAN"><svg><use xlink:href="#svg-associate-taan"></use></svg></span></li>
									<li><span class="svg-wrap svg-nma" title="NMA"><svg><use xlink:href="#svg-associate-nma"></use></svg></span></li>
									<li><span class="svg-wrap svg-keep" title="KEEP"><svg><use xlink:href="#svg-associate-keep"></use></svg></span></li>
								</ul>
								<span class="footnote-piece-title">Payment Mode</span>
								<ul class="footnote-piece-elist payment">
									<li><span class="svg-wrap svg-secure" title="Secure Payment"><svg><use xlink:href="#svg-pay-secure"></use></svg></span></li>
									<li><span class="svg-wrap svg-visa" title="Visa"><svg><use xlink:href="#svg-pay-visa"></use></svg></span></li>
									<li><span class="svg-wrap svg-mastercard" title="Master Card"><svg><use xlink:href="#svg-pay-mastercard"></use></svg></span></li>
									<li><span class="svg-wrap svg-axpress" title="American Express"><svg><use xlink:href="#svg-pay-americanexpress"></use></svg></span></li>
								</ul>
							</div>
              </div>
              <div class="col xs-12 sm-12 md-3">
                <div class="footnote__logo">
                  <a href="index.html"><img src="../assets/images/logo.svg" alt="Nepal Alternative Trekking Company"></a>
                </div>
                <div class="footnote__contact">
                  <dl>
                    <dt>Contact</dt>
                    <dd><a href="tel:+9779851130491">+977-9851130491</a></dd>
                  </dl>
                  <dl>
                    <dt>Email</dt>
                    <dd><a href="mailto:info@nepalalternativetreks.com">info@nepalalternativetreks.com</a></dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div class="foobar">
            <span class="copy">Copyright 2021, Nepal Alternative Treks & Expedition. All Rights Reserved</span>
            <span class="siteby">
              Designed By: <a href="#"><img src="images/" alt="Wishers Tech Pvt. Ltd."></a>
            </span>
          </div>
        </div>
      </footer>
</template>

<script>
export default {
    name:'Footer'
}
</script>