<template>
<header id="header" class="site-header">
    <div class="container">
        <div class="header__frame">
            <div class="site-logo">
                <router-link :to="{name:'Home'}">
                    <img src="../assets/images/logo.svg" alt="Site Name" width="250" height="29" onerror="this.onerror=null; this.src='images/logo.png'">
                </router-link>
            </div>
            <ul class="header__control">
                <li><button class="tgr-search cta-search" type="button"><span class="icon-search"></span></button></li>
                <li><button class="tgr-nav cta-nav" type="button"><span class="bar">Menu</span></button></li>
            </ul>
            <div class="header__group">
                <div class="caller order-3">
                    <span class="caller-text">Talk to an Expert</span>
                    <a href="tel:+9779851130491" class="caller-tel">977-9851130491</a>
                </div>
                <nav class="site-nav order-1">
                    <ul>
                        <li>
                            <router-link to="/">Packages</router-link>
                            <ul>
                                <li><router-link :to="{name:'Featuredcategory'}">Featured Package</router-link></li>
                                <li><router-link :to="{name:'AwesomePackagecategory'}">Awesome Package</router-link></li>
                                <li><router-link :to="{name:'ComboPackagecategory'}">Combo Package</router-link></li>
                                <li><router-link :to="{name:'Allpackage'}">All Packages</router-link></li>
                            </ul>
                        </li>
                        <li>
                            <router-link :to="{name:'Packagecategory',params:{slug:'trekking'}}">Trekking</router-link>
                            <ul>
                                <li><router-link :to="{name:'Packagecategory',params:{slug:'everest-region'}}">Everest Region</router-link></li>
                                <li><router-link :to="{name:'Packagecategory',params:{slug:'annapurna-region'}}">Annapurna Region</router-link></li>
                                <li><router-link :to="{name:'Packagecategory',params:{slug:'manaslu-region'}}">Manaslu Region</router-link></li>
                                <li><router-link :to="{name:'Packagecategory',params:{slug:'langtang-region'}}">Langtang Region</router-link></li>
                            </ul>
                        </li>
                        <li>
                            <router-link :to="{name:'Packagecategory',params:{slug:'peak-climbing'}}">Climbing</router-link>
                            <ul>
                                <li><router-link :to="{name:'Packagecategory',params:{slug:'everest-region-peak-climbing'}}">Everest Region</router-link></li>
                                <li><router-link :to="{name:'Packagecategory',params:{slug:'annapurna-region-peak-climbing'}}">Annapurna Region</router-link></li>
                                <li><router-link :to="{name:'Packagecategory',params:{slug:'manaslu-region-peak-climbing'}}">Manaslu Region</router-link></li>
                            </ul>
                        </li>
                        <li>
                            <router-link :to="{name:'Packagecategory',params:{slug:'hiking'}}">Hiking</router-link>
                        </li>
                        <li>
                            <router-link :to="{name:'Packagecategory',params:{slug:'tours',title:'Tours',keyword:'Tours',description:'Tours'}}">Tours</router-link>
                        </li>
                        <li>
                            <router-link :to="{name:'About'}">About Us</router-link>
                        </li>
                        <li>
                            <router-link :to="{name:'Blog'}">Blog</router-link>
                        </li>
                    </ul>
                </nav>
                <button class="tgr-search cta-search hidden-xs order-2" type="button"><span class="icon-search"></span></button>
            </div>
            <div class="site-search" style="display: none;">
						<form class="general-form search-form">
							<div class="form-set">
								<input type="search" placeholder="Search packages" name="searchname" v-model="searchform">
							</div>
							<div class="form-action">
								<router-link :to="{name:'Search',params:{searchkey:this.searchform}}" class="btn btn-secondary">Search</router-link>
							</div>
						</form>
					</div>
        </div>
    </div>
</header>
</template>

<script>
import jQuery from 'jquery';
export default {
    name: 'Header',
    data(){
        return{
            searchform:'everest'
        }
    },
    async mounted(){
        jQuery('.tgr-search').click(function() {
            jQuery(this).toggleClass('search-active');
            jQuery('.site-search').slideToggle();
        });
        jQuery(".site-nav>ul>li").hover(function() {
            jQuery(this).children("ul").slideDown();
            jQuery(this).siblings().children("ul").slideUp();
            jQuery('.tgr-search').removeClass('search-active');
            jQuery('.site-search').slideUp();
        }, function(){
            jQuery(this).children("ul").slideUp();
        });
        jQuery('.tgr-nav').click(function() {
            jQuery('body').toggleClass('nav-active');
        });
    }
}
</script>
