<template>
  <Header/>
  <!-- <Mainslider/> -->
  <router-view></router-view>
    <!-- <transition name="route" mode="out-in">
      <component :is="Component"></component>
    </transition> -->
  <!-- <router-view v-slot="{ Component }">
  <transition name="fade">
    <component :is="Component" />
  </transition>
</router-view> -->
  <Footer/>
</template>

<script>
import Header from "./components/Header.vue";
// import Mainslider from "./components/Mainslider.vue";
import Footer from "./components/Footer.vue";
export default {
  name: 'App',
  components: {
    Header,
    Footer,
    // Mainslider,
  }
}
</script>

<style>
/*Router Transitions */
.fade-enter-from,.fade-leave-to{
  opacity:0;
  transform: translateX(100px);
}
.fader-enter-active,.fade.leave-active{
  transition: all 0.3s ease-out;
}
.fade-enter-to,.fade-leave-from{
  opacity: 0;
  transform: translateX(-100px);
}
.route.leave-active{
  transition: all 0.3s ease-in;
}
</style>
