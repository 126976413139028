import { createApp } from 'vue'
import App from './App.vue'
import '@/assets/css/main.css'
import "@/assets/js/svg-sprites.js";
// import "../node_modules/slick-carousel/slick/slick.css";
// import '@/assets/js/jquery-3.6.0.min.js'
// import '@/assets/js/svg-sprites.js'
// import '@/assets/js/jquery.main.js'
import router from './route'
// import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';


// createApp(App).use(router,VueFilterDateFormat).mount('#app')
createApp(App).use(router).mount('#app')
