import { createWebHistory, createRouter } from "vue-router";

const routes=[
    {
        name:'Home',
        path:'/',
        // component:Home,
        component: () => import(/* webpackChunkName: "Home" */ './components/Home.vue'),
        
    },
    {
        name:'About',
        path:'/about/',
        component: () => import(/* webpackChunkName: "About" */ './components/About.vue'),
        
    },
    {
        name:'Contact',
        path:'/contact',
        component: () => import(/* webpackChunkName: "Contact" */ './components/Contact.vue'),
       
    },
    {
        name:'Packagecategory',
        path:'/category/:slug',
        component: () => import(/* webpackChunkName: "Packagecategory" */ './components/Packagecategory.vue'),
    },
    {
        name:'Featuredcategory',
        path:'/featuredpackage',
        component: () => import(/* webpackChunkName: "Featured" */ './components/Featured.vue'),
    },
    {
        name:'AwesomePackagecategory',
        path:'/awesomepackage',
        component: () => import(/* webpackChunkName: "Awesome" */ './components/Awesome.vue'),
    },
    {
        name:'ComboPackagecategory',
        path:'/combopackage',
        component: () => import(/* webpackChunkName: "Combo" */ './components/Combo.vue'),
    },
    {
        name:'Allpackage',
        path:'/packages',
        component: () => import(/* webpackChunkName: "Allpackage" */ './components/Allpackage.vue'),
    },
    {
        name:'Packagedetail',
        path:'/package/:slug',
        component: () => import(/* webpackChunkName: "Packagedetail" */ './components/Packagedetail.vue'),
        props:true,
    },
    {
        name:'Blog',
        path:'/blog',
        component: () => import(/* webpackChunkName: "Blog" */ './components/Blog.vue'),
       
    },
    {
        name:'Blogdetail',
        path:'/blog/:slug',
        component: () => import(/* webpackChunkName: "Blogdetail" */ './components/Blogdetail.vue'),
        props:true,
    },
    {
        name:'Booking',
        path:"/booking/:slug/:td",
        component: () => import(/* webpackChunkName: "Booking" */ './components/Booking.vue'),
        props:true,
    },
    {
        name:'BookingConfirm',
        path:"/confirm-booking",
        component:()=>import(/* webpackChunkName: "Thankyou" */ './components/Thankyou.vue'),
        props:true,
    },
    {
        name:'Enquire',
        path:"/enquire/:slug",
        component:()=>import(/* webpackChunkName: "Enquire" */ './components/Enquire.vue'),
        props:true,
    },
    {
        name:'EnquiryConfirm',
        path:"/confirm-enquiry",
        component:()=>import(/* webpackChunkName: "Enquireconfirm" */ './components/Enquireconfirm.vue'),
        props:true,
    },
    {
        name:'QueryConfirm',
        path:"/confirm-query",
        component:()=>import(/* webpackChunkName: "Queryconfirm" */ './components/Queryconfirm.vue'),
        props:true,
    },
    {
        name:'TailormadeQuery',
        path:'/tailormade/:slug',
        component:()=>import(/*webpackChunkName: "TailormadeQuery"*/ './components/Tailormade.vue'),
        props:true,
    },
    {
        name:'Team',
        path:'/ourteam',
        component:()=>import(/*webpackChunkName: "Team"*/ './components/Team.vue'),
    },
    {
        name:'Search',
        path:'/search/:searchkey',
        component:()=>import(/*webpackChunkName: "Search"*/ './components/Searchcomponent.vue'),
        props:true
    },
    {
        name:'Page',
        path:'/page/:slug',
        component: () => import(/* webpackChunkName: "Contact" */ './components/Page.vue'),
       
    },
]


const router = createRouter({
    history:createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { 
            top: 0,
            behavior: 'smooth',
        }
      },
    routes
})
// router.beforeEach((to,from,next)=>{
//     console.log(to);
//     if(to.params.title){
//         document.title = `${to.params.title} :: ${process.env.VUE_APP_TITLE}`;
//     }else{
//         document.title = `${to.name} :: ${process.env.VUE_APP_TITLE}`;
//     }
//     next();
// })
export default router;